import { createStore } from 'vuex'
import { expirationLS } from '../expiration'

// Create a new store instance.
const store = createStore({
    state () {
        return {
            domain: expirationLS.getWithExpiry('domain') || '',
            accessKey: expirationLS.getWithExpiry('accessKey') || '',
            salesChannelId: expirationLS.getWithExpiry('salesChannelId') || '',
            prodNum: expirationLS.getWithExpiry('prodNum') || '',
            showThumbnail: expirationLS.getWithExpiry('showThumbnail') || '',
            currencies: {}
        }
    },
    mutations: {
        changeSetting (state, {stateName, stateValue}) {
            state[stateName] = stateValue;
        },

        addCurrency (state, {accessKey, currency}) {
            state.currencies[accessKey] = currency;
        }
    }
});

export default store
