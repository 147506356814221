<template>
    <router-view/>
</template>

<script>
export default {
    name: 'ShopContentPWA',
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
