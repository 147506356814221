<template>
	<section>
		<div class="w-full h-screen flex flex-col justify-center bg-secondary">
			<div class="px-5">
				<dl class="block max-w-screen-lg w-full mx-auto text-white text-left sm:text-right">
					<div v-for="(config, fieldName) in this.fields" :key="fieldName" class="sm:flex flex-wrap mt-8 sm:mt-4">
						<dt class="flex-grow sm:w-1/3">
							<label v-if="config.input !== 'checkbox'" class="block sm:py-3 mb-2 sm:mb-0 ellipsis" :for="fieldName">{{ config.label }}:</label>
						</dt>
						<dd class="text-left flex-grow sm:w-2/3 sm:pl-4">

							<input v-if="config.input === 'checkbox'"
									class="form-checkbox rounded cursor-pointer w-6 h-6 text-primary focus:outline-none focus:ring-transparent"
									type="checkbox"
									:id="fieldName"
									:name="fieldName"
									v-model="params[fieldName]"
									:checked="config.default">
							<label v-if="config.input === 'checkbox'"
									class="sm:py-3 ml-4 cursor-pointer mb-2 sm:mb-0 ellipsis"
									:for="fieldName">{{ config.label }}</label>

							<input v-if="config.input === 'text'"
									class="w-full py-3 px-6 rounded-full bg-transparent text-white border-white border-2 focus:border-primary transition transition-all duration-500 ease-out"
									type="text"
                                    :id="fieldName"
                                    :name="fieldName"
                                    v-model="params[fieldName]"
                                    :placeholder="config.default"
                                    @keyup.enter="applyChanges()"
                                    @blur="checkField(fieldName)">

							<select v-if="config.input === 'select'"
									class="w-full py-3 px-6 rounded-full bg-transparent text-white border-white border-2 focus:border-primary transition transition-all duration-500 ease-out"
									:id="fieldName"
									@change="config.change($event, config.options)"
									@blur="checkField(fieldName)">
								<option :selected="config.default === ''" value="">Choose {{ config.label.toLowerCase() }}</option>

								<option v-for="(val, index) in config.options.filter(i => i.visible)"
										:key="index"
										:value="index"
										:selected="parseInt(index) === parseInt(config.default)">{{ val.label }}</option>
							</select>

						</dd>
					</div>
				</dl>
				<button @click="applyChanges()" class="mt-8 py-3 px-6 sm:px-20 rounded-full bg-white hover:bg-black hover:text-white transition transition-all duration-500 ease-out">Apply changes</button>
			</div>
		</div>
	</section>
</template>

<script>
    import { expiryTime } from '@/constants'
    import { expirationLS } from '@/expiration'

    export default {
        name: 'Config',

		data() {

            /**
             * If a new property is added to the endpoint options value object (e.g. "accessKey"),
             * the corresponding store state must be added here: src/store/index.js
             * **/
            const endpointOptions = [
                {
                    label: 'revendo AT',
                    value: {accessKey: 'SWSCADRPQMF4Y0R3V2NGYVVRZG', domain: 'https://sw.revendo.eu', salesChannelId: '82f9acae94a54faf8ce67f281c84d4dc'},
                    visible: true
                },
                {
                    label: 'revendo CH',
                    value: {accessKey: 'SWSCRLBQEB2ZTOO7KZVMWLUC-G', domain: 'https://sw.revendo.eu', salesChannelId: '65dcbb382a8b4bd2802a8426e56b0139'},
                    visible: true
                },
                {
                    label: 'revendo EU',
                    value: {accessKey: 'SWSCZW9YMJLQTGE3BLRBEEHOMW', domain: 'https://sw.revendo.eu', salesChannelId: 'e3c33613a9074639966dde737244edb6'},
                    visible: true
                },
                {
                    label: 'revendo AT (dev)',
                    value: {accessKey: 'SWSCADRPQMF4Y0R3V2NGYVVRZG', domain: 'https://sw.revendo.dev', salesChannelId: '82f9acae94a54faf8ce67f281c84d4dc'},
                    visible: process.env.NODE_ENV ? process.env.NODE_ENV.indexOf('production') === -1 : false
                },
				{
					label: 'revendo CH (dev)',
					value: {accessKey: 'SWSCRLBQEB2ZTOO7KZVMWLUC-G', domain: 'https://sw.revendo.dev', salesChannelId: '65dcbb382a8b4bd2802a8426e56b0139'},
					visible: process.env.NODE_ENV ? process.env.NODE_ENV.indexOf('production') === -1 : false
				},
				{
					label: 'revendo EU (dev)',
					value: {accessKey: 'SWSCZW9YMJLQTGE3BLRBEEHOMW', domain: 'https://sw.revendo.dev', salesChannelId: 'e3c33613a9074639966dde737244edb6'},
					visible: process.env.NODE_ENV ? process.env.NODE_ENV.indexOf('production') === -1 : false
				},
				{
					label: 'revendo EU (local)',
					value: {accessKey: 'SWSCZW9YMJLQTGE3BLRBEEHOMW', domain: 'http://revendo.cat', salesChannelId: 'e3c33613a9074639966dde737244edb6'},
                    visible: process.env.NODE_ENV ? process.env.NODE_ENV.indexOf('production') === -1 : false
				}
			];
            const endpointParamNames = ['accessKey', 'domain', 'salesChannelId'];

			return {
                params: this.filledParams(),

				fields: {
                    domain: {
						input: 'select',
						type: String,
						label: 'Host',
						default: this.findOptionsIndex(endpointOptions, endpointParamNames),
						required: true,
						localStorage: true,
						options: endpointOptions,
						paramNames: endpointParamNames,
						change: (event, options) => {
							this.setParams(endpointParamNames, event, options);
                        }
					},

					prodNum: {
						input: 'text',
						type: String,
						label: 'Product Number',
						default: this.getParamValue('prodNum') || '12345',
                        required: true,
                        localStorage: true
					},

                    showThumbnail: {
                        input: 'checkbox',
						type: Boolean,
						label: 'Show thumbnail',
						default: false,
						required: false,
                        localStorage: true
                    },
				}
			}
		},

        methods: {
            filledParams() {
                // Initialize parameters with current values
                const paramNames = ['domain', 'accessKey', 'salesChannelId', 'prodNum', 'showThumbnail'];

                // Initialize parameters with current values
                let filledParams = {};
                for (const param of paramNames) {
                    filledParams[param] = this.getParamValue(param);
                }

                return filledParams;
            },

            setParams(paramNames, event, options) {
                for (let i in paramNames) {
                    const paramName = paramNames[i];
                    this.params[paramName] = event.target.value === '' ? '' : options[event.target.value].value[paramName];
                }
            },

			storeParams() {
                const paramNames = Object.keys(this.params);

                for (let i in paramNames) {
                    const paramName = paramNames[i];
					switch (this.getParamStorageStrategy(paramName)) {
						case 'localStorage':
							expirationLS.setWithExpiry(paramName, this.params[paramName], 1000 * 60 * 60 * 24 * expiryTime);
                            this.$store.commit('changeSetting', {
                                stateName: paramName,
								stateValue: this.params[paramName]
                            });
                            break;

						case 'storeState':
                            this.$store.commit('changeSetting', {
                                stateName: paramName,
                                stateValue: this.params[paramName]
                            });
                            break;

						default:
                            this.$store.commit('changeSetting', {
                                stateName: paramName,
                                stateValue: this.params[paramName]
                            });
					}
                }
			},

            applyChanges() {

                if (this.checkFields()) {

                    this.storeParams();

                    this.$router.push({
                        name: 'shopContent',
                    });

                }
            },

            findOptionsIndex(options, paramNames) {
                let keys = {};
                for (let p in paramNames) {
                    const paramName = paramNames[p];
                    keys[paramName] = this.getParamValue(paramName);
                }
                for (let i in options) {
                    let match = true;
                    for (let key in keys) {
                        match &= options[i].value[key] === keys[key];
                    }
                    if (match) return i;
                }
            },

			/**
            * Returns storage strategy of specified parameter.
			* Default storage strategy is 'storeState' unless it is set to 'localStorage' in field attribute.
	        */
			getParamStorageStrategy(paramName) {
				for (let fieldName in this.fields) {
                    const field = this.fields[fieldName];
                    // Simple field (without object values)
					if (fieldName === paramName) {
						return field.localStorage ? 'localStorage' : 'storeState';
					}
					// Complex field (with object value)
					if (Array.isArray(field.paramNames)) {
						if (field.paramNames.includes(paramName)) {
                            return field.localStorage ? 'localStorage' : 'storeState';
						}
					}
				}
				// Fallback strategy
				return 'storeState';
			},

            /**
             * Returns value of specified parameter according to its storage strategy.
             * @param paramName
             * @returns {*}
             */
            getParamValue(paramName) {
				switch(this.getParamStorageStrategy(paramName)) {
					case 'localStorage':
                        return expirationLS.getWithExpiry(paramName) || this.$store.state[paramName];
					case 'storeState':
                        return this.$store.state[paramName];
					default:
                        return this.$store.state[paramName];
                }
            },

			fieldPass(fieldKey) {
                return this.params[fieldKey] !== '' && this.params[fieldKey] !== undefined && this.params[fieldKey] !== 'undefined';
			},

			checkFields() {
                let passed = true;
				for (let fieldKey in this.fields) {

					let fieldVal = this.fields[fieldKey];
					let field = document.getElementById(fieldKey);

					if (fieldVal.required) {
                        let pass = this.fieldPass(fieldKey);
                        passed &= pass;

                        switch (fieldVal.input) {
                            case 'select':
                                this.markField(field, pass);
                                break;
                            case 'text':
                                this.markField(field, pass);
                                break;
							case 'radio':
								// @TODO: not implemented yet
								break;
							case 'checkbox':
                                // Either checked or not is a valid state
								break;
                            default:
                                this.markField(field, pass);
                                break;
                        }
					}
				}

				return passed;
			},

            checkField(fieldName) {
				this.markField(document.getElementById(fieldName), this.fieldPass(fieldName));
            },

			markField(field, pass) {
                if (!pass) {
                    field.classList.add('border-danger');
                    field.classList.remove('border-primary','border-white');
                } else {
                    field.classList.add('border-white');
                    field.classList.remove('border-primary','border-danger');
                }
			}
		},

		beforeMount() {
            clearInterval(window.interval);
        }
    }
</script>
