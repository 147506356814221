import { createApp } from 'vue/dist/vue.esm-bundler.js'
import App from './App.vue'
import router from './router'
import store from './store';
import Vue3TouchEvents from 'vue3-touch-events'
import './styles/app.css';
import './registerServiceWorker'
import './workboxCacheDefinition'

const shopApp = createApp(App);
shopApp.use(router);
shopApp.use(store);
shopApp.use(Vue3TouchEvents);
shopApp.mount('#app');
