<template>
    <section v-show="errored" class="w-full h-screen flex flex-col items-center justify-center bg-danger p-5">
        <p class="text-white">
            <span class="material-icons-outlined sm:text-3xl align-bottom">warning</span>
            We're sorry! This device's information is not available. Please try back later.
        </p>
        <small class="text-white mt-2 break-words block" v-html="error"></small>
        <router-link class="my-8" to="/config">
            <div class="bg-white hover:bg-gray-100 py-2 px-6 rounded-full">
                Return to config
            </div>
        </router-link>
    </section>

    <section v-show="!errored">
        <div class="fixed top-0 left-0 right-0 bottom-0 h-screen overflow-hidden bg-gray-700 flex flex-col items-center justify-center transition transition-all duration-500 ease-out z-50 pointer-events-none"
             :class="loading ? 'opacity-90' : 'opacity-0'">
            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
            <h3 class="text-center text-white font-semibold mb-2">Loading...</h3>
            <p class="mx-5 text-center text-white">This may take a few seconds, please don't close this page.</p>
        </div>

        <div v-if="init" class="justify-center flex flex-col bg-primary">
            <div class="product-cover relative w-full h-screen flex flex-wrap items-center justify-center">
                <div class="grid grid-areas-slim sm:grid-areas-wide grid-rows-slim sm:grid-rows-wide max-h-screen-5 max-w-screen-lg p-3% rounded-xl translate-z-0"
                    :class="{'flex-1': !product.showThumbnail}">
                    <div class="grid-in-info mb-5 px-3">
                        <h1 class="line-clamp line-clamp-5 mb-1 text-white">
                            {{ product.name }}
                        </h1>
                        <h3 v-if="product.subtitle" class="line-clamp text-white">
                            {{ product.subtitle }}
                        </h3>
                    </div>
                    <div class="grid-in-price sm:justify-self-end sm:z-20">
                        <div v-if="product.listPrice" class="list-price relative table text-white w-fit-content text-sm strike-price leading-3 h-3 mx-auto mb-2">
                            {{ product.listPrice.price }} {{ settings.currency }}
                        </div>
                        <div class="price inline-block rounded-full border border-white text-3xl text-white font-bold leading-tight py-1 px-6 bg-primary"
                             v-touch:swipe.right="switchToConfig">
                                <span v-if="product.fromPrice" class="from-price text-lg">ab </span>{{ product.price }} {{ settings.currency }} 
                        </div>
                    </div>
                    <div v-if="product.showStock" class="grid-in-stock mt-5 text-center sm:text-left text-green-800 sm:text-white text-sm sm:text-base self-center line-clamp">
                        <span v-if="product.availableStock > 0 && product.availableStock <= 10">{{ stockPrefix }} {{ product.availableStock }} auf Lager</span>
                        <span v-if="product.availableStock > 10">Mehr als 10 Stück verfügbar</span>
                        <span v-if="product.availableStock === 0">AUSVERKAUFT</span>
                    </div>
                    <div v-if="product.showThumbnail && product.cover.media" class="grid-in-image overflow-hidden align-self-stretch grid place-items-center w-full rounded-lg mt-5">
                        <img class="block object-contain object-center w-full h-full max-w-full max-h-full overflow-hidden rounded-xl transform hover:scale-105 transition transition-transform duration-500 ease-out"
                          :src="product.cover.media.url"
                          :srcset="product.srcSet"
                          sizes="(max-width: 2500px) and (min-width: 1200px) 1360px, (max-width: 1199px) and (min-width: 992px) 1199px, (max-width: 991px) and (min-width: 768px) 991px, (max-width: 767px) and (min-width: 576px) 767px, (max-width: 575px) and (min-width: 0px) 575px, 100vw"
                          :alt="product.name">
                    </div>
                    <span v-if="product.listPrice > product.price" class="absolute block bg-white text-5xl rounded-full w-2/12 md:w-1/3 right-3% bottom-10% max-w-100 text-center leading-none font-bold before-square">
                        <span class="font-8vw sm:text-5xl absolute top-2/4 inset-x-0 transform -translate-y-2/4 grid items-center p-10% overflow-hidden hyphens hyphens-2">
                            %
                        </span>
                    </span>
                </div>

                <!-- Tabs height placeholder -->
                <div class="w-full mt-auto h-16 flex-1 flex-grow-0 flex-shrink-0"></div>

                <!-- Tabs backdrop -->
                <div @click="showMore(false)" class="bg-black opacity-0 transition transition-opacity duration-500 ease-out" :class="more ? 'h-screen absolute inset-0 opacity-60' : ''"></div>

                <!-- Tabs -->
                <div class="bg-white fixed bottom-0 rounded-t-2xl z-20 h-16 w-full transition-height duration-500 ease-out" :class="more ? 'h-screen-m-5' : ''">

                    <div @click="showMore()" v-touch:swipe="swipeMore()" v-touch-options="{swipeTolerance: 10}" class="w-full cursor-pointer py-2">
                        <span class="material-icons-outlined text-4xl transform transition transition-transform duration-500 ease-out" :class="more ? 'rotate-180' : ''">expand_less</span>
                    </div>
                    <div class="sm:hidden">
                        <select id="current-tab"
                                name="current-tab"
                                @change="currentTab = $event.target.value"
                                class="block w-full outline-none mb-4 px-5 py-3 text-left font-normal sm:font-bold">
                            <option v-for="tab in tabs" :key="tab.id" :selected="tab.id === currentTab" :value="tab.id">{{ tab.name }}</option>
                        </select>
                    </div>

                    <div class="hidden px-5 sm:block">
                        <nav class="-mb-px flex justify-center space-x-8 max-w-screen-lg mx-auto pb-5">
                            <a v-for="tab in tabs"
                               :key="tab.id"
                               :href="tab.href"
                               @click="currentTab = tab.id"
                               class="transition-all duration-500 ease-out whitespace-nowrap pb-2 px-1 border-b-2 font-medium text-sm"
                               :class="tab.id === currentTab || tabs.length === 1 ? 'border-primary text-primary' : 'border-transparent text-gray-600 hover:text-gray-800 hover:border-gray-300'">
                                <h4 class="mb-0">{{ tab.name }}</h4>
                            </a>
                        </nav>
                    </div>

                    <div class="h-full-content overflow-auto">
                        <transition name="show-card" mode="out-in" v-for="tab in tabs" :key="tab.id">
                            <div class="px-5 mb-5">
                                <div class="product-info max-w-screen-lg mx-auto text-left">
                                    <component v-if="currentTab === tab.id || tabs.length === 1" :is="tab.template.name" :props="tab.template.props"></component>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import axios from 'axios'
    import {expirationLS} from '../expiration'
    import {showStock} from '../constants'

    export default {
        name: 'ShopContent',

        components: {
            specs: {
                props: ['props'],
                template: `
                    <div v-for="prop in props.product.specifications" v-bind:key="prop.id" class="flex justify-between py-4 border-b-2 border-gray-400">
                        <div class="font-bold text-left ellipsis min-w-1/4">{{ prop.group.translated.name }}</div>
                        <div class="text-right pl-4 max-h-36 overflow-hidden line-clamp line-clamp-5">{{ prop.translated.name }}</div>
                    </div>`
            },
            description: {
                props: ['props'],
                template: `
                    <h3>{{ props.product.name }}</h3>
                    <div v-html="props.product.description"></div>`
            }
        },

        data () {
            return {
                settings: {
                    host: this.getConfigValue('domain'),
                    headers: {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'sw-access-key': this.getConfigValue('accessKey')
                        }
                    },
                    body: {
                        'associations': {
                            'properties': {
                                'associations': {
                                    'group': {}
                                }
                            },
                            'children': {
                                'associations': {
                                    'rvagProductStock': {}
                                }
                            }
                        },
                        'filter': [
                            {
                                'type': 'multi',
                                'operator': 'and',
                                'queries': [
                                    {
                                        'type': 'equals',
                                        'field': 'productNumber',
                                        'value': this.getConfigValue('prodNum')
                                    },
                                    {
                                        'type': 'multi',
                                        'operator': 'or',
                                        'queries': [
                                            {
                                                'type': 'equals',
                                                'field': 'childCount',
                                                'value': 0
                                            },
                                            {
                                                'type': 'multi',
                                                'operator': 'and',
                                                'queries': [
                                                    {
                                                        'type': 'equals',
                                                        'field': 'children.rvagProductStock.salesChannelId',
                                                        'value': this.getConfigValue('salesChannelId')
                                                    },
                                                    {
                                                        'type': 'equals',
                                                        'field': 'children.rvagProductStock.available',
                                                        'value': true
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        'includes': {
                            'product': [
                                'availableStock',
                                'calculatedPrice',
                                'calculatedPrices',
                                'childCount',
                                'children',
                                'cover',
                                'customFields',
                                'description',
                                'id',
                                'name',
                                'productNumber',
                                'properties',
                                'translated'
                            ]
                        }
                    },
                    currency: this.$store.state.currencies[this.getConfigValue('accessKey')]
                },

                init: false,
                loading: true,
                error: '',
                errored: false,
                product: {
                    name: null,
                    subtitle: null,
                    description: null,
                    price: 0,
                    listPrice: 0,
                    availableStock: 0,
                    specifications: [],
                    cover: {},
                    srcSet: null,
                    fromPrice: false,
                    showThumbnail: this.getConfigValue('showThumbnail'),
                    showStock: showStock,
                    isChild: false,
                },
                more: false,
                currentTab: 'specs',
                tabs: []
            }
        },

        computed: {
            stockPrefix() {
                return !this.product.isChild ? 'Nur noch' : 'Min.';
            }
        },

        methods: {
            isSetupComplete () {
                if (!this.getConfigValue('domain') ||
                    !this.getConfigValue('accessKey') ||
                    !this.getConfigValue('salesChannelId') ||
                    !this.getConfigValue('prodNum')) {

                    // Redirect to config screen
                    this.$router.push({
                        name: 'config'
                    });
                    return false;
                }

                return true;
            },

            switchToConfig () {
                // Go to config screen
                this.$router.push({
                    name: 'config'
                });
            },

            showMore (open) {
                this.more = open ? open : !this.more;
            },

            swipeMore() {
                return (direction) => {
                    switch (direction) {
                        case 'top':
                            this.showMore(true);
                            break;
                        case 'bottom':
                            this.showMore(false);
                            break;
                    }
                };
            },

            setTabs () {
                // Reset tabs
                this.tabs = [];

                // Specs tab
                if (this.product.specifications[0]) {
                    if (this.product.specifications[0].group) {
                        this.tabs.push({
                            id: 'specs',
                            name: 'TECHNISCHE DATEN',
                            href: '#',
                            template: {
                                name: 'specs',
                                props: {
                                    product: this.product
                                }
                            }
                        });
                    }
                }

                // Description tab
                if (this.product.description) {
                    this.tabs.push({
                        id: 'details',
                        name: 'DETAILS',
                        href: '#',
                        template: {
                            name: 'description',
                            props: {
                                product: this.product
                            }
                        }
                    });
                }
            },

            generateSrcSet (media) {

                if (!media) return '';

                let maxWidth = 0;
                let srcSet = [];
                // Add thumbnails
                for (const thumbnail of media.thumbnails) {
                    srcSet.push(thumbnail.url + ' ' + thumbnail.width + 'w');
                    if (thumbnail.width > maxWidth) {
                           maxWidth = thumbnail.width;
                    }
                }
                // Add original size
                srcSet.push(media.url + ' ' + (maxWidth + 1) + 'w');
                return srcSet.join(', ');
            },

            triggerError (errMsg) {
                this.error = errMsg;
                this.errored = true;
            },

            getConfigValue (key) {
                return expirationLS.getWithExpiry(key) || this.$store.state[key];
            },

            getCheapestProductId(parent) {
                if(!parent.customFields?.cheapestProduct) return false;
                return parent.customFields.cheapestProduct[this.getConfigValue('salesChannelId')];
            },

            getCheapestChild(parent) {
                const cheapestChildId = this.getCheapestProductId(parent);
                const cheapestChild = parent.children.filter(child => child.id === cheapestChildId)[0];
                if (!cheapestChild) {
                    console.error(`Cheapest child product couldn't be found. ${parent.children.length} product(s) were analysed.`);
                    return false;
                }
                return cheapestChild;
            },

            setProduct(element, price, stock, isFromPrice) {
                this.product = {
                    ...this.product, ...{
                        name: element.translated.name,
                        description: element.translated.description,
                        price: price.unitPrice,
                        listPrice: price.listPrice,
                        availableStock: stock,
                        specifications: element.properties,
                        cover: element.cover,
                        srcSet: this.generateSrcSet(element.cover.media),
                        fromPrice: isFromPrice
                    }
                };
            },

            setParentProduct(parent) {

                /** Shopware core price logic for simple products
                 * @reference storefront/Resources/views/storefront/page/product-detail/buy-widget-price.html.twig:78
                 */
                let price = parent.calculatedPrice;

                if (parent.calculatedPrices.length === 1) {
                    price = parent.calculatedPrices[0];
                }

                this.setProduct(parent,
                    price,
                    parent.availableStock,
                    false);
                this.setTabs();
                this.init = true;
                this.loading = false;
            },

            loadData () {

                if (this.isSetupComplete()) {

                    let requests = [];
                    const currencyIsEmpty = !this.$store.state.currencies[this.getConfigValue('accessKey')];

                    // Currency request
                    if (currencyIsEmpty) {
                        requests.push(
                                axios.get(this.settings.host + '/store-api/context',
                                        this.settings.headers)
                        );
                    }

                    // Product request
                    requests.push(
                            axios.post(this.settings.host + '/store-api/product',
                                    this.settings.body, this.settings.headers)
                    );

                    // Send chain of requests
                    axios.all(requests).then(results => {

                        let reqIndex = 0;

                        if (currencyIsEmpty) {
                            // Currency request
                            if (!results[reqIndex].data.currency) {
                                this.triggerError(`No currency found in the selected host (${this.settings.host}).`);
                                return false;
                            }

                            const currency = results[reqIndex].data.currency.symbol;
                            this.settings.currency = currency;

                            this.$store.commit('addCurrency', {
                                accessKey: this.getConfigValue('accessKey'),
                                currency: currency
                            });

                            reqIndex++;
                        }

                        // Not existing product
                        if (results[reqIndex].data.elements.length === 0) {
                            this.triggerError(`No product found with this criteria (prodNum: ${this.getConfigValue('prodNum')}).`);
                            return false;
                        }

                        const parent = results[reqIndex].data.elements[0];

                        // Simple product, without children
                        if (parent.childCount === 0) {
                            this.setParentProduct(parent);
                            return false;
                        }

                        const cheapestChild = this.getCheapestChild(parent);
                        const cheapestProductId = this.getCheapestProductId(parent);

                        if (!cheapestChild) {
                            // Since cheapest child wasn't found, we show parent product
                            this.setParentProduct(parent);
                            const withID = cheapestProductId ? ` with ID ${cheapestProductId}` : '';
                            console.error(`Cheapest child${withID} not found. Showing parent product.`);
                            return false;
                        }

                        if (!parent.customFields?.cheapestAvailablePrice?.unitPrice) {
                            this.triggerError(`Missing <pre class="inline">customFields.cheapestAvailablePrice</pre> for parent product with ID ${parent.id}.`);
                            return false;
                        }

                        this.setProduct(
                            parent,
                            parent.customFields.cheapestAvailablePrice,
                            cheapestChild.availableStock,
                            parent.customFields.hasAvailableChildren
                        );
                        this.product.isChild = true;
                        this.setTabs();

                    }).catch(error => {
                        if (error.response !== undefined) {
                            this.triggerError(error.response.data.errors[0].detail);
                        } else {
                            this.triggerError(error);
                        }
                        console.error(error);
                    }).finally(() => {
                        this.init = true;
                        this.loading = false;
                    });
                }
            }
        },

        beforeMount () {
            clearInterval(window.interval);
            this.loadData();

            // Load data every 1 hour
            window.interval = setInterval(() => {
                this.loading = true;
                this.loadData()
            }, 60 * 60 * 1000);
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.max-w-100 {
    max-width: 100px;
}
.h-screen-m-5 {
    height: calc(100vh - 4rem);
}
.h-full-content {
    height: calc(100% - 8.2rem);
}
.max-h-screen-5 {
    max-height: calc(100vh - 4rem);
}
.before-square:before {
    content: "";
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 0;
    height: 0;
    padding-bottom: 100%;
}
.strike-price {
    background: linear-gradient(to left top, transparent 46%, currentColor 49%, currentColor 52%, transparent 46%);
}
.show-card-enter-active, .show-card-leave-active {
    transition: opacity .5s ease-out;
}
.show-card-enter-from, .show-card-leave-to {
    opacity: 0;
}
.product-info >>> p {
    margin: 0;
}
.product-info >>> p + p {
    text-indent: 3ch;
}
.loader {
    border-top-color: #0c6;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}
@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@media (max-width: 400px) {
    .grid-in-price .price {
        font-size: 7vw;
    }
    .grid-in-price .from-price {
        font-size: 5vw;
    }
    .grid-in-price .list-price {
        font-size: 4vw;
    }
}
</style>
