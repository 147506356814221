import Config from './../components/Config';
import ShopContent from './../components/ShopContent'
import {createRouter, createWebHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'shopContent',
        component: ShopContent,
        props: true
    },
    {
        path: '/config',
        name: 'config',
        component: Config
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router
